@import "../../styles/colors";

.SimpleRecipeCard {
    position:relative;
    // margin: 1rem 0;
    margin: 0;
    padding: 1rem;
    background-color: $primary;
    border-radius: 0 0 1rem 1rem;
    align-items: stretch;
    height: fit-content;
    flex-direction: column;

    @media (min-width:600px) {
        flex-direction: row;
        height: 325px;
    }
}

.SimpleRecipeCard-RecipeInfo {
    border-radius: 5px;
    color: $bright-white;
    height: 100%;
    padding: 1rem 0 1rem 0;
    flex: 1 1 50%;


    @media (min-width:600px) {
        padding: 0 1rem 0 1rem;
    }

    .RecipeInfo-sourceName a,
    .RecipeInfo-owner a {
        color: $bright-white;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 2px;
    }

    .RecipeInfo-name {
        color: $secondary;
        text-decoration: none;
    }
}

.SimpleRecipeCard-imageContainer {
    display: block;
    flex: 1 0 30%;
    height: 100%;
    line-height: 0;
    position: relative;

    &> :hover {
        outline: $secondary 2px solid;
    }

    .SimpleRecipeCard-image {
        height: 100%;
        background-size: cover;
        background-position: center center;

        @media (max-width:600px) {
            min-height: 200px;
        }
    }

}



.IconBar {
    margin: 1rem 0 0 0;
    border-radius: 1rem 1rem 0 0;
    width:100%;

    background: linear-gradient($primary, $primary-dark);
    padding: 5px;
    padding-right:1rem;
    border: none;
    color:$primary
}