@import '../../styles/_recipe-mixins.scss';

.RecipeInfoInput {
    background-color: #fcf7f0;
    padding: 1rem;
    border-radius: 5px;

    & > * {
        margin-top:.5rem;
    }

    .RecipeInfo-editImage {
        color:$bright-white;
        font-size: 1rem;
        background-color: $primary;
        border-radius: 5px;
        font-size: .75rem;


        border:none;

        position:absolute;
        top:-3rem;
        right:0px;
    }

    .RecipeInfo-editImage:hover, .RecipeInfo-editImage:focus {
        cursor: pointer;
        background-color: $salmon;
    }

    .RecipeInfoInput-name {
        input, textArea {
            font-size: 2rem;
            color: $primary;
            font-family:'Shrikhand', Georgia;
            line-height: 2rem;
        }
    }

    .MuiTextField-root {
        display:block;

    }


}

.EditImageModal {
    background-color: $bright-white;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    // width: 400px;
    width:80%;
    padding:1rem;
}