@import '../../styles/_colors.scss';

.GenerateRecipeDisplay {
    color: $charcoal;

    h2 {
        color: $primary;
    }
}

.GenerateRecipeDisplay-nav {
    width: 100%;
    background-color: $muted-dark;
    display: grid;
    grid-template-columns: 10rem 10rem;
    gap: 1rem;
    padding:10px 0;

    @media(min-width: 700px){
        grid-template-columns: 10rem 10rem 10rem 10rem;
    }
    @media(min-width: 900px){
        grid-template-columns: 10rem 10rem 10rem 10rem;
    }
}

.GenerateRecipeDisplay-button {
    width: 8rem;
    margin: auto;
    color: $bright-white;
    border: $primary solid 1px;
    border-radius: 0;
    background-color: $muted-dark;
    grid-column: span 1 ;

    &.selected {
        background-color: $bright-white;
        border: 2px $primary solid;
        color: $primary;
    }
}

.GenerateRecipeDisplay-button:Hover {
    background-color: $bright-white;
    color: $primary;
}

.GenerateRecipeDisplay-contents {
    min-height: 400px;
    padding: 1rem;
    width: 100%;
}

.GenerateRecipeDisplay-formContainer {
    height: 100%;
    width: 100%;
}